
.bubble {
	display: inline-block;
	padding-top: calc(var(--extra-radius)/1.25) ;
	padding-inline: calc(var(--extra-radius)*1.5);
	padding-bottom: calc(var(--extra-radius)/1.25);
	border-radius: calc(var(--extra-radius)/1.5);
	margin: 0 0 var(--extra-radius) 0;
	text-decoration: none;
	transform-origin: center;
	max-width: 33ch;
	
	font-weight: 400;
	line-height: 1.6em;

	box-sizing: border-box;

  h5 {
    font-weight: 800;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }

	h6 {
		font-weight: 800;
		text-transform: uppercase;
	}
  
}



a.bubble {
	text-decoration: none;
	color: var(--white);
}

.bubble i {
	// font-size: 16px;
	margin-right: 10px;
}

.bubble-black {
	position: relative;
	z-index: 0;
	color: var(--white);
	box-shadow: var(--box-shadow-small);
	background-color: var(--black) !important;
	box-sizing: border-box;
  	-webkit-transform: translateX(0);
  	transform: translateX(0);
	letter-spacing: 0.025em;
}

.bubble-gray {
	color: var(--black);
	background-color: var(--gray-medium) !important;
	max-width: none;
}

.bubble .bubble-light {
	color: var(--black);
	background-color: var(--gray-light);
}


.bubble-block {
	display: block;

	hyphens: auto;
	/*padding: calc(var(--border-radius)*1.25);*/
	margin: 0 0 var(--spacer) 0;

	max-width: unset;
	min-width: unset;
	width: auto;
	height: auto;
}

.bubble-block .linebreak {
	display: block;
	content: '';
	margin-top: var(--border-radius);
}

@media screen and (min-width: 500px) {

	.bubble-block {
		display: inline-block;

		box-sizing: border-box;
		break-inside: avoid-column;

		width: 100%;
		height: 100%;
	}
}