/* Fonts */

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-Black.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO Black It';
	src: url('../typeface/subset-BwNistaGroteskDEMO-BlackItalic.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-ExtraBold.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-ExtraBoldItalic.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-ExtraBoldItalic.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-Bold.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-BoldItalic.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-MediumItalic.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-Medium.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-Light.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-LightItalic.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}


@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-Thin.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-Thin.woff') format('woff');
	font-weight: 250;
	font-style: normal;
}


@font-face {
	font-family: 'Bw Nista Grotesk DEMO';
	src: url('../typeface/subset-BwNistaGroteskDEMO-ThinItalic.woff2') format('woff2'),
	url('../typeface/subset-BwNistaGroteskDEMO-ThinItalic.woff') format('woff');
	font-weight: 250;
	font-style: italic;
}







@font-face {
    font-family: 'Times Now';
    src: url('../typeface/TimesNow-LightItalic.woff2') format('woff2'),
        url('../typeface/TimesNow-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Times Now';
    src: url('../typeface/TimesNow-Light.woff2') format('woff2'),
        url('../typeface/TimesNow-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times Now';
    src: url('../typeface/TimesNow-SemLight.woff2') format('woff2'),
        url('../typeface/TimesNow-SemLight.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times Now';
    src: url('../typeface/TimesNow-SemLightItalic.woff2') format('woff2'),
        url('../typeface/TimesNow-SemLightItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Times Now';
    src: url('../typeface/TimesNow-SemiBold.woff2') format('woff2'),
        url('../typeface/TimesNow-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times Now';
    src: url('../typeface/TimesNow-SemiBoldItalic.woff2') format('woff2'),
        url('../typeface/TimesNow-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}