/*Animations*/
.fade-element-out-enter-active,
.fade-element-out-leave-active {
	transition: opacity 1s ease-in-out;
}

.fade-element-out-enter,
.fade-element-out-leave-to {
	opacity: 0;
}

.fade-element-in-enter-active {
	transition: opacity 1s ease-in-out;
}

.fade-element-in-enter {
	opacity: 0;
}

@keyframes buzz-out-on-hover {
	20% {
		translate: 0% 8%;
	}
	40% {
		translate: 0% -5%;
	}
	60% {
		translate: 0% 3%;
	}
	80% {
		translate: 0% -1%;
	}
	100% {
		translate: 0% 0%;
	}
}

@keyframes propeller {
	0% {
		rotate: 0deg;
	}
	100% {
		rotate: 90deg;
	}
}