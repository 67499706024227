body {
	font-family: 'Bw Nista Grotesk DEMO';
	letter-spacing: -0.01em;
	text-decoration: none;
	font-weight: 700;
	font-size: var(--font-size);
	line-height: var(--line-height);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.text-grid {
	display: inline-block;
	text-decoration: none;
	hyphens: auto;
	transform-origin: center;
}

p:not(.no-hyphens) {
	hyphens: auto;
}

h1,
.typography-editorial-big {
	font-family: 'Times Now';
	font-weight: normal;
	font-size: var(--font-size-editorial-big);
	letter-spacing: -.05em;
	max-width: 120vmin;
	text-align: center;
	margin-inline: auto;
	text-wrap: balance;
	
	line-height: var(--line-height-editorial-big);
	
	* {
		line-height: inherit;
	}

  @supports (width: clamp(80%, 750px, 100%)) {
    width: clamp(80%, 750px, 100%);
    max-width: initial;
  }
}

h1 p {
	line-height: var(--line-height-editorial-big);
}

.typography-editorial-medium {
	font-family: 'Times Now';
	font-weight: 600;
	font-size: var(--font-size-editorial-medium);
	line-height: var(--line-height-editorial-medium);
	letter-spacing: -.02em;
	margin-inline: auto;
	text-align: center;
	text-wrap: balance;

	// & > * {
	// 	all: inherit;
	// }
}

.typography-editorial-small {
	font-family: 'Times Now';
	font-weight: 600;
	font-size: var(--font-size-editorial-small);
	line-height: var(--line-height-editorial-small);
	letter-spacing: -.02em;
	max-width: 40ch;
	margin-inline: auto;
	text-wrap: balance;

	-webkit-hyphens: none !important;
	-moz-hyphens: none !important;
	hyphens: none !important;

	text-align: center;

	* {
		// all: inherit;
		// display: inline;
		// margin-top: unset;
		-webkit-hyphens: none !important;
		-moz-hyphens: none !important;
		hyphens: none !important;
		text-wrap: inherit;
	}
}

.typography-headline-bodytext {
	font-weight: 900;
	font-size: var(--font-size-headline-bodytext);
	text-wrap: balance;
}

h6 {
	text-transform: uppercase;
	font-weight: 900;
	font-size: 1em;
}

a {
	color: currentColor;
}

.preheadline {
	font-weight: bold;
	text-align: center;
}

.subheadline-explanatory {
	max-width: 36ch;
	text-wrap: balance;
	
	& > * + * {
		margin-top: var(--spacer-em-half);
	}
}

button {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	color: currentColor;
	cursor: pointer;
}

// p,
// button {
// 	line-height: 1.6em;
// 	line-height: calc(1.5em + 0.1vw);
// }

p + p {
	margin-block-start: var(--spacer-em-half);
}

.annotation {
	font-weight: 600;
	opacity: var(--opacity-low);
}

b {
	font-weight: 900;
}