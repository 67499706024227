:root {
	/* Colors */
	--black-values: 25, 25, 25;
	--black: rgb(var(--black-values));

	--darkgray: rgb(50, 50, 50);
	--white: white;
	--gray-light: rgb(250, 250, 250);
	--gray-mediumlight: rgb(225, 225, 225);
	--gray-medium: rgb(200, 200, 200);
	--gray-dark: rgb(140, 140, 140);

	/* Navigation Bar */
	--navigation-bar-height: calc( 100px + env(safe-area-inset-bottom) + env(safe-area-inset-bottom));
	--navigation-bar-elements-outer-padding: var(--spacer);

	/* Spacers */
	--spacer: 7.5vw;
	--spacer: calc(7vw + 1rem);
	--spacer-rem-fallback: 3rem;
	--spacer-block-with-fallback: max(var(--spacer), var(--spacer-rem-fallback));
	--spacer-em-half: 0.5em;
	--spacer-padding: 7.5vmin;
	--padding-outside: calc(8.5vw - 1rem);
	--max-width: max(125vmin, 70rem);
	
	/* Tags */
	--tags-padding-inline: 0.5em;
	--tags-padding-block: 0.4em;

	/* Border Radius */
	--border-radius: 5px;
	--extra-radius: calc(11px - .2vw);

	/* Box Shadows */
	--box-shadow-small: 0px calc(var(--border-radius)/2) var(--border-radius) rgba(0,0,0,.25);
	--box-shadow-small-hover: 0px calc(var(--border-radius) / 4) calc(var(--border-radius) / 2) rgba(0,0,0,.5);

	--box-shadow-phone: 0px calc(var(--border-radius) / 2) calc(var(--border-radius) * 2) rgba(0,0,0,.15);

	/* Typography */
	--font-size: 16px;
	--font-size: calc(14px + .175vmin);
	--font-size: max(calc(14px + .175vmin), 1em);
	--line-height: 1.6em;
	--line-height: calc(1.4em + 0.1vw);
	
	--font-size-editorial-big: clamp(2.8rem, (1.8rem + 4vw), 5.9rem);
	--font-size-editorial-medium: clamp(2.1rem, 8vw, 4.4rem);
	--font-size-editorial-small: clamp(1.8rem, 6vw, 2.6rem);
	
	/* Transitions */
	--transition-speed: .05s;
	--transition-easing: ease-in-out;
	--easing: cubic-bezier(.5, -0.55, 0.265, 1.55);
	
	/* Colum Gaps */
	--column-gap: calc(var(--spacer) * .5);
	
	/* Opacity */
	--opacity-very-low: 0.1;
	--opacity-low: 0.2;
	--opacity-medium: 0.4;
	
	--line-height-editorial-big: calc(var(--font-size-editorial-big) * 0.95);
  --half-line-height-editorial-big: calc(var(--line-height-editorial-big) * 0.75);
		
	--hero-half-screen-offset: calc(50vh - var(--half-line-height-editorial-big) - var(--navigation-bar-height));
	
	--line-height-editorial-medium: calc(var(--font-size-editorial-medium) * 1);

	--line-height-editorial-small: calc(var(--font-size-editorial-small) * 1.05);
	
	--font-size-headline-bodytext: 1rem;

	--border-strenght: 2.5px;
	--length-line: .75em;
	--length-gap: calc(var(--length-line) / 1.5);
	--length-dashes: calc(var(--length-line) + var(--length-gap));

	--browser-bar-height: .75em;
}
