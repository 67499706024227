
@import '../assets/css/variables.css';

.grid-image {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	opacity: 1;

	/*Make Element unklickable for UpsMessage to appear*/
	pointer-events: none;
}

.fade-image-enter-active,
.fade-image-leave-active {
	transition: opacity .5s ease;
}

.fade-image-enter,
.fade-image-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.style-browser-bar {
	border-top: var(--browser-bar-height) solid var(--gray-medium);
}

.style-square-corners {
	/* border-radius: 0; */
}

.grid-image>video {
	width: 100%;
}

.style-mobile-browser {
	box-shadow: var(--box-shadow-phone);
	// border-radius: var(--border-radius);
	position: relative
}

.style-browser {
	box-shadow: var(--box-shadow-small);

	&::before {
		content: "";
		display: block;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(240,240,240, .25);
		z-index: 0;
		pointer-events: none;

		@supports (mix-blend-mode: multiply) {
			mix-blend-mode: multiply;
		}
	}
}


