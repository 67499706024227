
@import './assets/css/reset.css';
@import './assets/css/animations.css';
@import './assets/css/layouts.scss';
@import './assets/css/modifiers.scss';
@import './assets/css/fonts.css';
@import './assets/css/typography.scss';
@import './assets/css/utilitys.scss';
@import './assets/css/variables.css';
@import './assets/css/scss-variables.scss';
@import './assets/css/bubbles.scss';

#router-element {
	padding: var(--padding-outside);
	margin-inline: auto;
}
